html,
body {
  font-family: 'Roboto', sans-serif;
  font-display: swap;

  /* FIX MOBILE WIDTH */
  overflow-x: hidden;
  scroll-behavior: auto;
}
@font-face {
  font-family: 'Roboto', sans-serif;
  font-display: swap;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #434242;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #918e8f;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: inset 7px 10px 12px #f9f9f9;
}

/* REMOVE CHAKRA FOCUS*/
*:focus {
  box-shadow: none !important;
}
*[data-focus] {
  box-shadow: none !important;
}
* {
  -webkit-tap-highlight-color: transparent;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  /* background: none !important; */
  display: none;
}

/* a[href*="https://www.google.com/maps"]
{
  display: none !important;
}
a[href*="https://maps.google.com"]
{
  display: none !important;
} */

/* cookiebot */
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: #ffb802 !important;
  border-color: #ffb802 !important;
  /* color: #ffb802 !important; */
}

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
  border-color: #ffb802 !important;
}

#CookiebotWidget .CookiebotWidget-consent-details button {
  color: #ffb802 !important;
}

#CookiebotWidget {
  background-color: #ffb802 !important;
  border-color: #ffb802 !important;
  color: #ffb802 !important;
}

#CookiebotWidget
  .CookiebotWidget-body
  .CookiebotWidget-consents-list
  li.CookiebotWidget-approved
  svg {
  fill: #ffb802 !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .avoid-break-inside,
  .avoid-break-inside * {
    break-inside: avoid !important;
  }

  .page-break-before {
    page-break-before: always !important;
  }
}
